.wrap {
  background: #ffffff;
  padding: 24px;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    color: #151515;
    padding-bottom: 20px;
    margin: 0;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #151515;
    padding-top: 8px;
    margin: 0;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #151515;
    padding-top: 24px;
    margin: 0;
  }

  .button_next {
    display: flex;
    justify-content: flex-end;
    padding-top: 46px;
  }

  h5 {
    font-size: 18px;
    margin: 8px 0 0 0;
  }

  img {
    max-width: 100%;
  }

  .terminal {
    border: 1px solid black;
    margin-top: 8px;
    h5 {
      padding: 8px;
      margin: 0;
      font-size: 16px;
      background: gray;
    }
    p {
      padding: 8px;
    }
  }
}

.instruction_wrap {
  margin-top: 24px;
  margin-bottom: 16px;
  background: #fafafa;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 24px;

  .instruction_text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #151515;
  }

  ol {
    padding-left: 13px;

    li {
      padding-bottom: 8px;
    }

    li:last-child {
      padding-bottom: 0;
    }
  }
}
